// eslint-disable-next-line eslint-comments/disable-enable-pair -- not necessary
/* eslint-disable complexity -- complex switch is required */
import { AuthErrorCodes } from 'firebase/auth';

interface ErrorResponse {
  message: string;
  cta: string;
}

enum MissingFirebaseErrorCodes {
  INVALID_USER_IMPORT = 'auth/invalid-user-import',
  SESSION_EXPIRED = 'auth/session-expired',
  MISSING_CODE = 'auth/missing-code',
  INVALID_LOGIN_CREDENTIALS = 'auth/invalid-login-credentials',
}

const getFirebaseError = (firebaseErrorCode: string): ErrorResponse => {
  switch (firebaseErrorCode) {
    case AuthErrorCodes.INVALID_PASSWORD:
      return {
        message: 'Invalid password',
        cta: 'Make sure to use correct password.',
      };
    case AuthErrorCodes.CAPTCHA_CHECK_FAILED:
      return {
        message: 'Captcha check failed',
        cta: 'Complete the captcha challenge correctly.',
      };
    case AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
      return {
        message: 'Credential too old',
        cta: 'Please log in again to refresh your credentials.',
      };
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
      return {
        message: 'Too many log in attempts',
        cta: 'Wait for a while before trying again.',
      };
    case AuthErrorCodes.TOKEN_EXPIRED:
      return {
        message: 'Token expired',
        cta: 'Log in again to get a new token.',
      };
    case AuthErrorCodes.INVALID_CODE:
      return {
        message: 'Invalid verification code',
        cta: 'Check the code and try again.',
      };
    case AuthErrorCodes.USER_DELETED:
      return {
        message: 'User not found',
        cta: 'There is no user record corresponding to this identifier. The user may have been deleted.',
      };
    case AuthErrorCodes.INVALID_PHONE_NUMBER:
      return {
        message: 'Invalid phone number',
        cta: 'Please make sure that the correct phone numbers have been entered.',
      };
    case AuthErrorCodes.UNSUPPORTED_FIRST_FACTOR:
      return {
        message: 'MFA is not supported for the given sign in method',
        cta: 'If you signed in with the phone number, please sign out and sign in with email/password.',
      };
    case AuthErrorCodes.CODE_EXPIRED:
      return {
        message: 'Verification code expired',
        cta: 'Request a new verification code and try again.',
      };
    case MissingFirebaseErrorCodes.INVALID_USER_IMPORT:
      return {
        message: 'Invalid user import',
        cta: 'Please make sure that data of all users is correct before uploading.',
      };
    case MissingFirebaseErrorCodes.SESSION_EXPIRED:
      return {
        message: 'Session expired',
        cta: 'Log in again to continue.',
      };
    case MissingFirebaseErrorCodes.MISSING_CODE:
      return {
        message: 'Missing code',
        cta: 'Please enter the code from SMS or request a new one.',
      };
    case MissingFirebaseErrorCodes.INVALID_LOGIN_CREDENTIALS:
      return {
        message: 'Invalid login credentials',
        cta: 'Please make sure that the correct email and password have been entered.',
      };
    default:
      return {
        message: 'Unknown problem occurred',
        cta: `Please contact Fruitful support team and provide them with the error code: ${firebaseErrorCode}`,
      };
  }
};

export const parseFirebaseError = (firebaseErrorCode: string) => {
  const error = getFirebaseError(firebaseErrorCode);
  return `${error.message}: ${error.cta}`;
};
